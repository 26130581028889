@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-height: 700px) {
    .card-height-restrict {
        max-height: calc(100vh - 56px);
    }
}

@media (min-height: 701px) {
    .card-height-restrict {
        max-height: 665px;
    }
}
